export enum SimulationType {
  gps,
  line,
  tap
}

export enum CollectionEditTab {
  collection = 'collection',
  echoes = 'echoes',
  trajectories = 'trajectories',
  simulator = 'simulator'
}
