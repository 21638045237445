import { createSlice } from '@reduxjs/toolkit/react'

const initialState: IAuthState = {}

const authSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    setToken(state, action) {
      localStorage.setItem('echoesToken', action.payload)
      state.token = action.payload
    },
    clearToken(state) {
      localStorage.removeItem('echoesToken')
      state.token = undefined
    },
  }
})

export const {
  setToken,
  clearToken
} = authSlice.actions

export default authSlice.reducer
