import {
  ADD_UPLOAD,
  UPLOAD_SUCCESS,
  REMOVE_UPLOAD,
  UPDATE_UPLOAD,
  UPLOAD_ERROR,
} from '../constants/media.constants'
import { Action, Dispatch } from 'redux'
import { change as reduxFormChange, arrayPush, arraySplice } from 'redux-form'
import { merge } from 'lodash'
import { socket } from '../socket'
import { getImageProxySizes, uploadToSignedUrl } from '../api/modules/media.api'
import { store } from '../store/store'
import { randomString } from '../core/helpers'

export enum MediaRel {
  CoverPhoto = 'cover-photo',
  ProfilePhoto = 'profile-photo',
  Sound = 'sound',
  Intro = 'intro',
  Video = 'video'
}

export class Medium implements IMedium {
  rel: MediaRel
  href: string
  type: string
  constructor(rel: MediaRel, href = '', type = '') {
    this.rel = rel
    this.href = href
    this.type = type
  }
}

export enum UploadState {
  Starting = 'starting',
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error'
}

export class UploadReduxForm implements TUploadReduxForm {
  file: File
  url: string
  name: string
  type: UploadType // used as the save location and as feedback for the user
  medium: IMedium
  progress = 0
  state = UploadState.Starting
  message = ''
  show = true
  form: {
    nameTarget: string
    fieldNameTarget: string
    arrayPosition: number
  }
  destinationFormat: string
  contentType: string | undefined

  constructor(
    file: File,
    medium: IMedium,
    collectionId: string,
    name: string,
    type: UploadType,
    formNameTarget: string,
    fieldNameTarget: string,
    arrayPosition: number,
    destinationFormat = 'mp4',
    contentType?: string
  ) {
    this.destinationFormat = destinationFormat
    const url = `media/${type}s/${collectionId}/${randomString(
      16,
      '#a'
    )}.${file.name.split('.').pop()}`
    this.name = name
    this.url = url
    this.file = file
    this.type = type
    this.medium = medium
    this.form = {
      nameTarget: formNameTarget,
      fieldNameTarget,
      arrayPosition
    }
    this.contentType = contentType
  }
}

export class UploadBase implements IUploadBase {
  file: File
  url: string
  name: string
  type: UploadType // used as the save location and as feedback for the user
  medium: IMedium
  progress = 0
  state = UploadState.Starting
  message = ''
  show = true
  destinationFormat: string
  contentType: string | undefined

  constructor(
    file: File,
    medium: IMedium,
    collectionId: string,
    name: string,
    type: UploadType,
    destinationFormat = 'mp4',
    contentType?: string
  ) {
    this.destinationFormat = destinationFormat
    const url = `media/${type}s/${collectionId}/${randomString(
      16,
      '#a'
    )}.${file.name.split('.').pop()}`
    this.name = name
    this.url = url
    this.file = file
    this.type = type
    this.medium = medium
    this.contentType = contentType
  }
}

export enum UploadType {
  Sound = 'sound',
  Ambisonic = 'ambisonic',
  Echo = 'echo',
  Cover = 'cover',
  Image = 'image',
}

export async function addGenericUpload(upload: IUploadBase, progressCallback: (upload: IUploadBase) => void) {
  // upload the file
  const uploadRes = await uploadToSignedUrl(
    upload,
    (progressEvent) => {
      if (progressEvent.total) {
        // set the progress of the upload
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        progressCallback({ ...upload, progress })
      }
    }
  )
  // construct the location of the file
  const href = uploadRes.href
  // set the href as we're not converting
  upload.medium.href = href
  // set the src so we have the original
  upload.medium.src = { href }
  // set the type
  upload.medium.type = upload.type
  return upload
}

export async function addAudioUpload(upload: IUploadBase, progressCallback: (upload: IUploadBase) => void): Promise<IUploadBase> {
  let uploadRes
  try {
    // upload the file
    uploadRes = await uploadToSignedUrl(
      upload,
      (progressEvent) => {
        if (progressEvent.total) {
          // set the progress of the upload
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          progressCallback({ ...upload, progress })
        }
      }
    )
    // construct the location of the file
    const href = uploadRes.href
    upload.medium.src = { href }
    upload.medium.type = upload.type
    // we must transcode
    progressCallback(upload)
    const promise = new Promise<IUploadBase>((resolve, reject) => {
      socket.on('error', () => {
        reject()
      })
      socket.on('sync', (data) => {
        const mediaupload = data as IMediaUpload
        merge(upload.medium, mediaupload)
        upload.progress = mediaupload.progress
        progressCallback(upload)
        if (data.state === 'completed') {
          // acknowledge the upload as having succeeded
          merge(upload.medium, mediaupload)
          resolve(upload)
        }
      })
    })
    socket.emit('create', upload.medium, (data: IMediaUpload) => {
      const mediaupload = data as IMediaUpload
      merge(upload.medium, mediaupload)
      progressCallback(upload)
    })
    // from here the socket takes over
    return promise
  } catch (err) {
    const error = err as Error
    console.error(error)
    throw error
  }
}

export async function addImageUpload(upload: IUploadBase, progressCallback: (upload: IUploadBase) => void) {
  // upload the file
  const uploadRes = await uploadToSignedUrl(
    upload,
    (progressEvent) => {
      if (progressEvent.total) {
        // set the progress of the upload
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        progressCallback(
          { ...upload, progress }
        )
      }
    }
  )
  if (uploadRes) {
    // construct the location of the file
    const href = uploadRes.href
    upload.medium.href = href
    // get the image proxy sizes
    const { sizes } = await getImageProxySizes(upload.medium)
    upload.medium.sizes = sizes
    return upload
  } else {
    throw Error('Error uploading')
  }
}

export async function dispatchAddGenericUpload(dispatch: Dispatch, upload: UploadReduxForm) {
  dispatch({
    type: ADD_UPLOAD,
    payload: { upload },
  })
  try {
    const uploadSuccess = await addGenericUpload(upload, (_upload) => {
      merge(upload, _upload)
      dispatch({
        type: UPDATE_UPLOAD,
        payload: { _upload },
      })
    })
    dispatch({
      type: UPLOAD_SUCCESS,
      payload: { upload: uploadSuccess },
    })
    updateInForm(upload.medium as IMediaUpload)
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_UPLOAD,
          payload: { upload: uploadSuccess },
        }),
      5000
    )
  } catch (err) {
    const error = err as Error
    console.error(error.message)
    dispatch({
      type: UPLOAD_ERROR,
    })
  }
}

export async function dispatchAddAudioUpload(dispatch: Dispatch, upload: UploadReduxForm) {
  await dispatch({
    type: ADD_UPLOAD,
    payload: { upload },
  })
  let uploadRes
  try {
    // upload the file
    uploadRes = await uploadToSignedUrl(
      upload,
      (progressEvent) => {
        if (progressEvent.total) {
          // set the progress of the upload
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...upload, progress } },
          })
        }
      }
    )
    // construct the location of the file
    const href = uploadRes.href
    upload.medium.src = { href }
    upload.medium.type = upload.type
    // we must transcode
    dispatch({
      type: UPDATE_UPLOAD,
      payload: { upload }
    })
    socket.emit('create', upload.medium, (data: IMediaUpload) => {
      const upload = data as IMediaUpload
      updateInForm(upload)
    })
    // from here the socket takes over

  } catch (err) {
    const error = err as Error
    console.error(error)
    dispatch({
      type: UPDATE_UPLOAD,
      payload: { upload },
    })
  }
}

export async function dispatchAddImageUpload(dispatch: Dispatch, upload: UploadReduxForm) {
  await dispatch({
    type: ADD_UPLOAD,
    payload: { upload },
  })
  let uploadRes
  try {
    // upload the file
    uploadRes = await uploadToSignedUrl(
      upload,
      (progressEvent) => {
        if (progressEvent.total) {
          // set the progress of the upload
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...upload, progress } },
          })
        }
      }
    )
  } catch (err) {
    const error = err as Error
    console.error(error)
    upload.state = UploadState.Error
    upload.message = error.message
    dispatch({
      type: UPDATE_UPLOAD,
      payload: { upload },
    })
  }
  if (uploadRes) {
    // construct the location of the file
    const href = uploadRes.href
    upload.medium.href = href
    // get the image proxy sizes
    try {
      const { sizes } = await getImageProxySizes(upload.medium)
      upload.medium.sizes = sizes
    } catch (err) {
      const error = err as Error
      console.error(error)
      upload.state = UploadState.Error
      upload.message = error.message
      dispatch({
        type: UPDATE_UPLOAD,
        payload: { upload },
      })
      return
    }
    dispatch({
      type: UPLOAD_SUCCESS,
      payload: { upload },
    })
    updateInForm({ ...upload.medium, progress: 100, state: UploadState.Completed })
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_UPLOAD,
          payload: { upload },
        }),
      5000
    )
  }
}

export async function dispatchAddUpload(dispatch: Dispatch, upload: UploadReduxForm) {
  await dispatch({
    type: ADD_UPLOAD,
    payload: { upload },
  })
  let uploadRes
  try {
    // upload the file
    uploadRes = await uploadToSignedUrl(
      upload,
      (progressEvent) => {
        if (progressEvent.total) {
          // set the progress of the upload
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          dispatch({
            type: UPDATE_UPLOAD,
            payload: { upload: { ...upload, progress } },
          })
        }
      }
    )
    // construct the location of the file
    const href = uploadRes.href
    if ([UploadType.Ambisonic, UploadType.Sound].includes(upload.type)) {
      upload.medium.src = { href }
      upload.medium.type = upload.type
      // we must transcode
      dispatch({
        type: UPDATE_UPLOAD,
        payload: { upload }
      })
      socket.emit('create', upload.medium, (data: IMediaUpload) => {
        const upload = data as IMediaUpload
        updateInForm(upload)
      })
      // from here the socket takes over
    } else {
      upload.medium.href = href
      // image upload type?
      if ([UploadType.Cover, UploadType.Image].includes(upload.type)) {
        // get the image proxy sizes
        try {
          const { sizes } = await getImageProxySizes(upload.medium)
          upload.medium.sizes = sizes
        } catch (err) {
          const error = err as Error
          console.error(error)
        }

      }
      dispatch({
        type: UPLOAD_SUCCESS,
        payload: { upload },
      })
      updateInForm({ ...upload.medium, progress: 100, state: UploadState.Completed })
      setTimeout(
        () =>
          dispatch({
            type: REMOVE_UPLOAD,
            payload: { upload },
          }),
        5000
      )
    }

  } catch (err) {
    const error = err as Error
    console.error(error)
    dispatch({
      type: UPDATE_UPLOAD,
      payload: { upload },
    })
  }
}

export function updateInForm(mediaupload: IMediaUpload): void {
  // set the uploaded file location to the href of the supplied medium
  const { media } = store.getState()
  if (media != undefined) {
    const { uploads } = media
    const _upload: IUploadBase | undefined = uploads.find((u) => u.medium._id === mediaupload._id || u.medium.href === mediaupload.href || u.medium.src?.href === mediaupload.src?.href)
    const upload = _upload as TUploadReduxForm
    if (upload !== undefined) {
      switch (upload.form.arrayPosition) {
        case -2: {
          merge(upload.medium, mediaupload)
          // the item is a plain object, not an array
          store.dispatch<Action<string>>(
            reduxFormChange(
              upload.form.nameTarget,
              upload.form.fieldNameTarget,
              upload.medium
            )
          )
          break
        }
        case -1:
          // we don't have any items in the array, or no matching items
          merge(upload.medium, mediaupload)
          store.dispatch<Action<string>>(
            arrayPush(
              upload.form.nameTarget,
              upload.form.fieldNameTarget,
              upload.medium
            )
          )
          break
        default:
          // we want to replace an item in the array
          merge(upload.medium, mediaupload)
          store.dispatch<Action<string>>(
            arraySplice(
              upload.form.nameTarget,
              upload.form.fieldNameTarget,
              upload.form.arrayPosition,
              1,
              upload.medium
            )
          )
          break
          break
      }
    }
  }
}
