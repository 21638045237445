import { Reducer } from 'redux'
import {
  GETTING_COLLECTIONS_FOR_PROFILE,
  GOT_COLLECTIONS_FOR_PROFILE,
  ERROR_GETTING_COLLECTIONS_FOR_PROFILE,
} from '../constants/profile.constants'
import { PubStatus } from '../core/collection'

export class ProfileModel {
  _id = undefined
  name = ''
  slug = ''
  description = [{
    lang: 'en',
    text: ''
  }]
  pub_status = PubStatus.draft
  active = false
  media = []
  creator = null
  privileges = []
}

const initialState: IProfileState = {
  gettingCollectionsForProfile: false,
  gotCollectionsForProfile: false,
  gettingCollectionsForProfileError: false,
  profileCollections: [],
  activateProfileModalOpen: false
}

const profile: Reducer<IProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: false,
        gettingCollectionsForProfile: true,
        gettingCollectionsForProfileError: false,
        profileCollections: []
      }
    case GOT_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: true,
        gettingCollectionsForProfile: false,
        gettingCollectionsForProfileError: false,
        profileCollections: action.payload
      }
    case ERROR_GETTING_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: false,
        gettingCollectionsForProfile: false,
        gettingCollectionsForProfileError: true,
        errorMessage: action.payload
      }
    default:
      return state
  }
}

export default profile
