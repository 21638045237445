
import { configureStore } from '@reduxjs/toolkit/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { reducer as formReducer } from 'redux-form'
import profile from '../reducers/profile.reducers'
import media from '../reducers/media.reducers'
import userProfile from '../reducers/userProfile.reducers'
import { api } from './apiSlice'
import { api as mapboxApi } from './mapboxApiSlice'
import creatorReducer from './creatorSlice'
import authReducer from './authSlice'

/* const resetState = () => {
  console.debug('Migration running')
  return {} as PersistedState
}

const migrations = {
  8: resetState,
  9: resetState,
}

const storage = process.env.NODE_ENV === 'production' ? localforage : localstorage

const persistConfig = {
  key: 'echoes-creator',
  version: 9,
  storage,
  blacklist: ['stats'],
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer) */

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [mapboxApi.reducerPath]: api.reducer,
    auth: authReducer,
    creator: creatorReducer,
    profile,
    media,
    userProfile,
    form: formReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware)
})

/* const store = createStore(
  persistedReducer,
  composeWithDevTools({
    actionsBlacklist: [
      '@@redux-form/REGISTER_FIELD',
      '@@redux-form/UNREGISTER_FIELD',
    ],
    trace: true,
    traceLimit: 25,
  })(applyMiddleware(thunk, promise))
) */
// const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export { store }
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
