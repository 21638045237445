import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'mapbox',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_MAPBOX_PLACES_API_URL,
  }),
  endpoints: build => ({
    searchForLocations: build.query<[ISearchedPlace], ISearchForLocationsArgs>({
      query: (args) => ({
        url: `${args.inputValue}.json`,
        method: 'GET',
        params: {
          access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
          types: import.meta.env.VITE_MAPBOX_GEOCODING_TYPES,
          proximity: args.currentPosition ? args.currentPosition?.join(',') : undefined
        },
      }),
    })
  })
})

export const {
  useSearchForLocationsQuery
} = api
