import Cookies from 'universal-cookie'
import { store } from '../store/store'
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

const cookies = new Cookies()

// Add a request interceptor
export const request = {
  success: (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const { echoes: { mutations } } = store.getState()
    if (!mutations.token) {
      console.error('no token in localstorage') // eslint-disable-line
    } else {
      const bearer = `Bearer ${mutations.token}`
      if (config.headers && config.headers.authorization !== bearer) {
        config.headers.authorization = bearer
      }
      const cookieToken = cookies.get('token')
      if (cookieToken !== mutations.token) {
        cookies.set('token', mutations.token)
      }
    }
    return config
  },
  // Do something with request error
  failure: (error: AxiosError): Promise<AxiosError> => {
    console.error(error)
    return Promise.reject(error)
  },
}

// Add a response interceptor
export const response = {
  success: (res: AxiosResponse<unknown>): AxiosResponse<unknown> => res,
  failure: (error: AxiosError): Promise<AxiosError> => {
    if (error.response && error.response.status === 401) {
      // @FIXME: reinstate logout
      // store.dispatch({ type: LOGOUT })
    }
    return Promise.reject(error)
  },
}
