import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import Cookies from 'universal-cookie'
import { RootState } from './store'
import { clearToken, setToken } from './authSlice'

const CollectionTag = 'Collection'
const UserTag = 'User'
const ProfileTag = 'Profile'
const SubscriptionTag = 'Subscription'

// create a new mutex
const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URI,
  prepareHeaders: (headers, api) => {
    let token = (api.getState() as RootState).auth.token
    if (!token) {
      token = localStorage.getItem('echoesToken') || undefined
    }
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
      const cookies = new Cookies()
      const cookieToken = cookies.get('token')
      if (cookieToken !== token) {
        cookies.set('token', token)
      }
    } else {
      console.debug("No token in redux")
    }
    return headers
  },

})
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          '/refreshToken',
          api,
          extraOptions
        )
        if (refreshResult.data) {
          api.dispatch(setToken(refreshResult.data))
          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(clearToken())
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export const api = createApi({
  reducerPath: 'echoes',
  tagTypes: [CollectionTag, ProfileTag, UserTag, SubscriptionTag],
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    // collections
    getAllCollections: build.query<ICollectionModel[], IWalkQueryParams>({
      query: () => '/api/v2/collections',
      providesTags: (result) =>
        result ? result.map(({ _id }) => ({ type: CollectionTag, id: _id })) : [],
    }),
    getMyCollections: build.query<{ data: ICollectionModel[], count: number }, IWalkQueryParams>({
      query: () => '/api/v2/collections/mine',
      transformResponse: (res: ICollectionModel[], meta) => {
        return { data: res, count: Number(meta?.response?.headers.get('X-Total-Count')) }
      },
      providesTags: (result) =>
        result ? result.data.map(({ _id }) => ({ type: CollectionTag, id: _id })) : [],
    }),
    getCollectionsForProfile: build.query<{ data: ICollectionModel[], count: number }, string>({
      query: (profile) => ({
        url: '/api/v2/collections/',
        params: {
          profiles: [profile]
        }
      }),
      transformResponse: (res: ICollectionModel[], meta) => {
        return { data: res, count: Number(meta?.response?.headers.get('X-Total-Count')) }
      },
      providesTags: (result) =>
        result ? result.data.map(({ _id }) => ({ type: CollectionTag, id: _id })) : [],
    }),
    getCollection: build.query<ICollectionModel, string>({
      query: (slug) => `/api/v2/collections/${slug}`,
      providesTags: (result) =>
        result ? [{ type: CollectionTag, id: result._id }] : [],
    }),
    getCollectionChannels: build.query<string[], string | undefined>({
      query: () => '/api/v2/collections/channels',
    }),
    getCollectionTags: build.query<string[], string | undefined>({
      query: () => '/api/v2/collections/tags',
    }),
    createCollection: build.mutation<ICollectionModel, Partial<ICollectionModel>>({
      query: (body) => ({
        url: '/api/v2/collections/channels',
        method: 'POST',
        body
      }),
      invalidatesTags: [CollectionTag]
    }),
    updateCollection: build.mutation<ICollectionModel, Partial<ICollectionModel>>({
      query: (body) => ({
        url: `/api/v2/collections/${body.slug}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [CollectionTag]
    }),
    deleteCollection: build.mutation<void, string>({
      query: (slug) => ({
        url: `/api/v2/collections/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CollectionTag]
    }),
    // profiles
    getAllProfiles: build.query<{ data: IProfileModel[], count: number }, IProfileParams>({
      query: () => '/api/v2/profiles',
      transformResponse: (res: IProfileModel[], meta) => {
        return { data: res, count: Number(meta?.response?.headers.get('X-Total-Count')) }
      },
      providesTags: (result) =>
        result ? result.data.map(({ _id }) => ({ type: ProfileTag, id: _id })) : [],
    }),
    getMyProfiles: build.query<IProfileModel[], IProfileParams>({
      query: (params) => ({
        params,
        url: '/api/v2/profiles/mine'
      }),
      providesTags: (result) =>
        result ? result.map(({ _id }) => ({ type: CollectionTag, id: _id })) : [],
      transformResponse: (response, meta) => {
        const count = meta?.response?.headers.get('X-Total-Count')
        console.debug(`Count is ${count}`)
        return response as IProfileModel[]
      }
    }),
    getProfile: build.query<IProfileModel, string>({
      query: (slug) => `/api/v2/profiles/${slug}`
    }),
    createProfile: build.mutation<IProfileModel, IProfileModel>({
      query: profile => ({
        url: '/api/v2/profiles',
        method: 'POST',
        body: profile
      }),
      invalidatesTags: [ProfileTag]
    }),
    updateProfile: build.mutation<IProfileModel, IProfileModel>({
      query: profile => ({
        url: `/api/v2/profiles/${profile.slug}`,
        method: 'PUT',
        body: profile
      }),
      invalidatesTags: [ProfileTag]
    }),
    deleteProfile: build.mutation<IProfileModel, string>({
      query: slug => ({
        url: `/api/v2/profiles/${slug}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ProfileTag]
    }),
    // subscriptions
    getAllSubscriptions: build.query<ISubscriptionModel[], ISubscriptionParams>({
      query: () => '/api/v2/subscriptions',
      providesTags: (result) =>
        result ? result.map(({ _id }) => ({ type: SubscriptionTag, id: _id })) : [],
    }),
    getMySubscriptions: build.query<TSubscriptionProfileModel[], ISubscriptionParams>({
      query: () => '/api/v2/subscriptions/mine',
      providesTags: (result) =>
        result ? result.map(({ _id }) => ({ type: SubscriptionTag, id: _id })) : [],
    }),
    getSubscription: build.query<ISubscriptionModel, string>({
      query: (id) => `/api/v2/profiles/${id}`,
      providesTags: (result) =>
        result ? [{ type: SubscriptionTag, id: result._id }] : [],
    }),
    createSubscription: build.mutation<ISubscriptionModel, ISubscriptionPaymentModel>({
      query: (body) => ({
        url: '/api/v2/subscriptions/',
        method: 'POST',
        body
      })
    }),
    goToStripePortal: build.mutation<{ url: string }, IGoToStripePortalData>({
      query: (body) => ({
        url: '/api/v2/subscriptions/create-customer-portal-session',
        method: 'POST',
        body
      })
    }),
    createStripeCheckoutSession: build.mutation<{ url: string }, IStripeCustomerCheckoutSessionData>({
      query: (body) => ({
        url: '/api/v2/subscriptions/create-checkout-session',
        method: 'POST',
        body
      })
    }),
    getSubscriptionProducts: build.query<ProductModel[], IProductParams>({
      query: () => '/api/v2/subscriptions/products'
    }),
    // user
    getAllUsers: build.query<IUserModel[], IUsersAdminParams>({
      query: () => '/api/v2/users',
      providesTags: (result) =>
        result ? result.map(({ _id }) => ({ type: UserTag, id: _id })) : [],
    }),
    getMyUser: build.query<IUserModel, any>({
      query: () => '/api/v2/users/me',
      providesTags: (result) => result && result._id ? [{ type: UserTag, id: result._id }] : [],
    }),
    getUser: build.query<IUserModel, string>({
      query: (slug) => `/api/v2/users/${slug}?populate=true`,
      providesTags: (result) => result && result._id ? [{ type: UserTag, id: result._id }] : [],
    }),
    createUser: build.mutation<IUserModel, Partial<IUserModel>>({
      query: (body) => ({
        url: '/api/v2/collections/users',
        method: 'POST',
        body
      }),
      invalidatesTags: [UserTag]
    }),
    updateUser: build.mutation<IUserModel, Partial<IUserModel>>({
      query: (body) => ({
        url: '/api/v2/collections/users',
        method: 'PUT',
        body
      }),
      invalidatesTags: [UserTag]
    }),
    // auth
    login: build.mutation<IAuthResponse, IAuthSignin>({
      query: (body) => ({
        url: '/auth/local',
        method: 'POST',
        body
      }),
    }),
    signup: build.mutation<IAuthResponse, IAuthSignup>({
      query: (body) => ({
        url: '/api/v2/users',
        method: 'POST',
        body
      }),
    }),
    requestPasswordReset: build.mutation<void, IAuthRequestReset>({
      query: (body) => ({
        url: '/api/v2/users/reset',
        method: 'POST',
        body
      }),
    }),
    resetPassword: build.mutation<IAuthResponse, IAuthResetPassword>({
      query: (body) => ({
        url: '/api/v2/users/reset',
        method: 'PUT',
        body
      }),
    }),
    // appConfig
    getAppConfig: build.query<IAppConfig, null>({
      query: () => '/api/v2/appconfig/creator.echoes.xyz',
    }),
    // media
    getImageProxySizes: build.query<IImageProxySizesResponse, IMedium>({
      query: (body) => ({
        url: '/api/v2/media/image/sizes',
        method: 'POST',
        body,
      }),
    }),
    uploadToSignedUrl: build.query<IFileUploadResponse, string>({
      query: (filename) => `/api/v2/media/signed-upload-url?filename=${filename}`,
    }),
    // @FIXME: create image upload function
    // stats
    getStatsTrends: build.query<ITrendStats, IStatQuery>({
      // @TODO: fix params serializer
      query: () => ({
        url: '/api/v2/stats/insights/trend/',
        method: 'GET',
      }),
    }),
    updateUserProfile: build.mutation<IUserModel, Partial<IUserModel>>({
      query: (body) => ({
        url: '/api/v2/users/me',
        method: 'PUT',
        body
      }),
    }),
    // map (separate api?)

  })
})

export const {
  useGetAllCollectionsQuery,
  useGetMyCollectionsQuery,
  useGetCollectionsForProfileQuery,
  useGetCollectionQuery,
  useGetCollectionChannelsQuery,
  useGetCollectionTagsQuery,
  useCreateCollectionMutation,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
  useGetAllProfilesQuery,
  useGetMyProfilesQuery,
  useGetProfileQuery,
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useDeleteProfileMutation,
  useGetAllSubscriptionsQuery,
  useGetMySubscriptionsQuery,
  useGetSubscriptionQuery,
  useCreateSubscriptionMutation,
  useCreateStripeCheckoutSessionMutation,
  useGoToStripePortalMutation,
  useGetSubscriptionProductsQuery,
  useGetAllUsersQuery,
  useGetMyUserQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useLoginMutation,
  useSignupMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useGetAppConfigQuery,
  useGetImageProxySizesQuery,
  useUploadToSignedUrlQuery,
  useGetStatsTrendsQuery,
  useUpdateUserProfileMutation
} = api
