import { Role } from '../core/user'
import { Reducer } from 'redux'

const initialState: IUserProfileState = {
  initialValues: {
    name: '',
    email: '',
    description: [],
    role: Role.user,
    capabilities: [],
    email_optin: false
  }
}

const auth: Reducer<IUserProfileState> = (state = initialState, action): IUserProfileState => {
  switch (action.type) {
    default:
      return state
  }
}

export default auth
